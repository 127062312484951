<template>
  <div>
    <div
      class="columns"
      v-for="item in Object.entries(studentData)"
      :key="item.id"
    >
      <div class="column is-3 primary py-0 py-1">
        <strong>{{ item[0] }}</strong>
      </div>
      <div class="column is-9 text-bg-color py-0 py-1">
        {{ item[1] ? item[1] : 'N/A' }}
      </div>
    </div>
    <div
      class="is-flex is-justify-content-end"
      v-if="role === 'host' || role === 'admin'"
    >
      <a
        class="is-small has-text-success"
        @click="initializeUpdate()"
        style="font-size: 1.2em; margin-left: 16px"
      >
        <fa-icon icon="edit" size="lg" />
      </a>
      <a
        class="is-small has-text-danger"
        @click="initializeRemove()"
        style="font-size: 1.2em; margin-left: 16px"
      >
        <fa-icon icon="trash-alt" size="lg" />
      </a>
    </div>
    <div
      class="columns is-multiline"
      v-if="role === 'host' || role === 'admin'"
    >
      <div class="column is-12">
        <p class="title is-6">ATE Result Sync</p>
      </div>
      <div class="column is-12 text-bg-color p-3">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-field label="School Class">
              <b-select
                id="school-class-id"
                v-model="schoolClassId"
                placeholder="Select a option"
                expanded
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                  :value="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Term">
              <b-select
                id="term-id"
                v-model="termId"
                placeholder="Select a option"
                expanded
              >
                <option v-for="term in terms" :key="term.id" :value="term.id">
                  {{ term.title }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-12 is-flex is-justify-content-end">
            <button
              type="submit"
              class="button btn-120 is-success is-capitalized"
              @click="initializeAtesSync()"
            >
              Sync
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-modal
      id="student-basic"
      modal-title="Student"
      :context="context"
      @create="create"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      termId: null,
      schoolClassId: null,
      modalTitle: '',
      context: '',
    }
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    schoolId: {
      type: Number,
      default: null,
    },
    student: {
      type: Object,
      default: () => {
        return {}
      },
    },
    terms: {
      type: Array,
      default: () => {
        return []
      },
    },
    schoolClasses: {
      type: Array,
      default: () => {
        return []
      },
    },
    studentData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    initializeUpdate() {
      this.$router.push(`/school/${this.schoolId}/student/${this.student.id}`)
    },
    initializeRemove() {
      this.context = 'remove'
      this.id = this.student.id
      this.openModal()
    },
    initializeAtesSync() {
      this.context = 'create'
      this.id = this.student.id
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteStudent($id: Int!) {
              deleteStudent(input: { id: $id }) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteStudent.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$router.replace(
              `/school/${this.$route.params.school_id}/students_in_school`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    create() {
      this.ateMarksSync()
    },
    ateMarksSync() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ateMarksSyncOperation(
              $id: Int!
              $termId: Int!
              $schoolClassId: Int!
            ) {
              ateMarksSync(
                input: {
                  id: $id
                  termId: $termId
                  schoolClassId: $schoolClassId
                }
              ) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.student.id),
            termId: parseInt(this.termId),
            schoolClassId: parseInt(this.schoolClassId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.ateMarksSync.errors,
            'Successfully created.'
          ).then(() => {
            this.closeModal()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
