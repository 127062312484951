<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div id="student-info">
        <div class="columns">
          <div class="column is-2 has-text-centered pt-5">
            <figure class="image is-96x96 is-inline-block">
              <img
                class="is-rounded"
                :src="student.imageUrl"
                alt="avatar"
                v-if="student.imageUrl"
              />
              <img
                class="is-rounded mt-5"
                src="@/assets/images/default_avatar.png"
                alt="avatar"
                v-else
              />
            </figure>
          </div>
          <div class="column">
            <template v-if="student">
              <b-tabs v-model="activeTab">
                <b-tab-item label="Basic">
                  <Basic
                    :schoolId="schoolId"
                    :role="user.role"
                    :terms="terms"
                    :schoolClasses="schoolClasses"
                    :studentData="studentData"
                    :student="student"
                  />
                </b-tab-item>
                <b-tab-item label="Performance">
                  <vue-chart
                    :raw-data="student.termlyPerformance"
                    v-if="activeTab === 1"
                  />
                </b-tab-item>
                <b-tab-item
                  label="Excluded Subjects"
                  v-if="user.role === 'host' || user.role === 'admin'"
                >
                  <ExcludedSubjects :termId="termId" :student="student" />
                </b-tab-item>
                <b-tab-item label="School Fee">
                  <SchoolFee
                    :role="user.role"
                    :schoolId="schoolId"
                    :student="student"
                    v-if="activeTab === 2 || activeTab === 3"
                  />
                </b-tab-item>
                <!-- <b-tab-item label="Online" v-if="user.role === 'admin'">
                  <Auth />
                </b-tab-item> -->
              </b-tabs>
            </template>
            <template v-else>No Student</template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TERMS from '../../../graphql/term/Terms.gql'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

import { fetchAppUrl, fetchUser } from '@/assets/js/app_info.js'
import { crudNotification } from '@/assets/js/notification'

import Basic from './components/Basic.vue'
// import SchoolFee from './components/SchoolFee.vue'
import ExcludedSubjects from './components/ExcludedSubjects.vue'
// import Auth from './components/Auth.vue'

export default {
  data() {
    return {
      url: null,
      user: {
        role: null,
      },
      schoolId: null,
      pageTitle: 'Student Info',
      student: {
        id: null,
        name: '',
        email: null,
        user: {
          firebaseUserId: '',
        },
        schoolFee: {},
        termSchoolFeePayments: [],
        excludedSubjectId: null,
      },
      studentData: {},
      activeTab: 0,
      context: null,
      removeStudent: true,
      id: null,
      action: '',
      terms: [],
      termId: '',
      schoolClasses: [],
      schoolClassId: '',
    }
  },
  watch: {
    terms(data) {
      this.termId = data[0].id
    },
    student(data) {
      this.selectedUser = data.user
      this.userAuth = this.isActivated()

      this.schoolClassId = data.schoolClass.id

      const {
        dateOfBirth,
        gender,
        name,
        ownerId,
        parentId,
        religion,
        schoolClass: { name: className },
        status,
        term: { title: title },
      } = data

      this.studentData = {
        Name: name,
        DOB: dateOfBirth,
        Gender: gender,
        'School Class': className,
        Religion: religion,
        Status: status,
        'Term Title': title,
      }

      this.$store.commit('setGraduate', {
        term_id: data.term.id,
        term_title: data.term.title,
        school_class_id: data.schoolClass.id,
        school_class_name: data.schoolClass.name,
        name: data.name,
        student_id: data.id,
      })

      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Class List',
            route: `/school/${this.$route.params.school_id}/students/${this.$route.params.school_class_id}`,
          },
          {
            name: 'Transfer',
            route: `/school/${this.$route.params.school_id}/student/${this.$route.params.id}/transfer`,
          },
          {
            name: 'Withdraw',
            route: `/school/${this.$route.params.school_id}/student/${this.$route.params.id}/withdraw`,
          },
          {
            name: 'Graduate',
            route: `/school/${this.$route.params.school_id}/student/${this.$route.params.id}/graduate`,
          },
          {
            name: 'Parent Info',
            route: `/school/${this.schoolId}/parent_info/${data.parentId}`,
          },
          // {
          //   name: 'Result Info',
          //   route: `/school/${this.$route.params.school_id}/school_class/${this.$route.params.school_class_id}/result_info/${this.$route.params.id}/term_id/${data.term.id}`,
          // },
        ])
      }
    },
    isModalOpen() {
      this.$apollo.queries.student.refetch()
      this.userAuth = this.isActivated()
    },
  },
  methods: {
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteStudent($id: Int!) {
              deleteStudent(input: { id: $id }) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteStudent.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$router.replace(
              `/school/${this.$route.params.school_id}/students_in_school`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    VueChart: () => import('@/components/vue-chart/VueChart'),
    Basic,
    SchoolFee: () => import('./components/SchoolFee.vue'),
    ExcludedSubjects,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchAppUrl().then((url) => {
      this.url = url
    })

    this.$apollo.addSmartQuery('student', {
      query: gql`
        query StudentQuery($id: ID!) {
          student(id: $id) {
            id
            imageUrl
            name
            dateOfBirth
            gender
            status
            religion
            email
            parentId
            schoolClass {
              id
              name
            }
            ownerId
            term {
              id
              title
            }
            user {
              id
              email
              firebaseUserId
            }
            termlyPerformance
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      fetchPolicy: 'network',
      error(error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
      },
    })

    // this.$apollo.queries.student.refetch()

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id

      this.$apollo.addSmartQuery('terms', {
        query: TERMS,
        variables: {
          schoolId: parseInt(this.schoolId),
        },
      })

      this.$apollo.queries.terms.refetch()

      this.$apollo.addSmartQuery('schoolClasses', {
        query: SCHOOL_CLASSES,
        variables: {
          schoolId: parseInt(this.schoolId),
        },
      })

      this.$apollo.queries.schoolClasses.refetch()

      if (this.$store.getters.getValue) {
        this.activeTab = this.$store.getters.getValue
      }

      if (this.user.role === 'parent') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Dashboard',
            route: `/school/${this.$route.params.school_id}/dashboard`,
          },
          {
            name: 'Students',
            route: `/school/${this.$route.params.school_id}/parents_students/${this.user.context}`,
          },
        ])
      } else if (this.user.role === 'educator') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Students In Class',
            route: `/school/${this.schoolId}/students/${this.$route.params.school_class_id}`,
          },
        ])
      }
    })
  },
}
</script>
