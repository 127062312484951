<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <SdSelect
        rules="required"
        label="Subject"
        v-model="student.excludedSubjectId"
      >
        <option
          v-for="data in student.assignedSubjects"
          :key="data.subject.id"
          :value="data.subject.id"
        >
          {{ data.subject.name }}
        </option>
      </SdSelect>
      <button
        type="submit"
        class="button btn-120 is-success is-capitalized is-pulled-right"
        @click="addExcludedSubject()"
      >
        Add
      </button>
    </div>
    <div class="column is-12">
      <b-table :data="student.excludedSubjects" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ student.excludedSubjects.indexOf(props.row) + 1 }}
        </b-table-column>
        <b-table-column label="Subject Name" v-slot="props">{{
          props.row.subject.name
        }}</b-table-column>
        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            :show-info="false"
            :show-update="false"
            @initialize-remove="initializeRemoveExcludedSubject(props.row)"
          />
        </b-table-column>
      </b-table>
    </div>
    <app-modal
      id="excluded-subjects"
      modal-title="Excluded Subject"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdSelect from '../../../components/SdSelect'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      student: {
        excludedSubjectId: null,
        excludedSubjects: [],
      },
    }
  },
  props: {
    termId: {
      type: String,
    },
  },
  components: {
    SdSelect,
  },
  methods: {
    currentExcludedSubjects() {
      return this.student.excludedSubjects.map(
        (excludedSubject) => excludedSubject.subject.id
      )
    },
    initializeRemoveExcludedSubject(excludedSubject) {
      this.id = excludedSubject.id
      this.openModal()
    },
    addExcludedSubject() {
      if (
        this.currentExcludedSubjects().includes(this.student.excludedSubjectId)
      ) {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'The subject has already been excluded.',
          position: 'is-top',
          type: 'is-info',
          hasIcon: true,
        })
        return
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createExcludedSubject(
              $termId: Int!
              $studentId: Int!
              $subjectId: Int!
            ) {
              createExcludedSubject(
                input: {
                  termId: $termId
                  studentId: $studentId
                  subjectId: $subjectId
                }
              ) {
                excludedSubject {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            termId: parseInt(this.termId),
            studentId: parseInt(this.student.id),
            subjectId: parseInt(this.student.excludedSubjectId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createExcludedSubject.errors,
            'Successfully created.'
          ).then(() => {
            this.$apollo.queries.student.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteExcludedSubject($id: Int!) {
              deleteExcludedSubject(input: { id: $id }) {
                excludedSubject {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteExcludedSubject.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.student.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.$apollo.addSmartQuery('student', {
      query: gql`
        query StudentQuery($id: ID!) {
          student(id: $id) {
            id
            assignedSubjects {
              subject {
                id
                name
              }
            }
            excludedSubjects {
              id
              subject {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      error(error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: true,
        })
      },
    })
  },
}
</script>
